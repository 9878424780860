import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RawLogProvider} from '../../providers/raw-log-provider.service';

@Component({
  selector: 'app-chat-log-dialog',
  templateUrl: './chat-log-dialog.component.html',
  styleUrls: ['./chat-log-dialog.component.scss']
})
export class ChatLogDialogComponent implements OnInit {

  public rawText = '';

  constructor(
    public dialogRef: MatDialogRef<ChatLogDialogComponent>,
    private rawLogsProvider: RawLogProvider,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.rawLogsProvider.getRawLog(this.data.guid, this.data.serverName)
        .subscribe((data) => this.rawText = data.rawText);
  }

}
