import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-chat-log-dialog",
  templateUrl: "./server-select-dialog.component.html",
  styleUrls: ["./server-select-dialog.component.scss"],
})
export class ServerSelectDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ServerSelectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
