import { ErrorMessage } from '../consts/error.consts';

export interface ApplicationError {
    code: number;
    message: string;
    stackTrace: any;
}

export class ClientUnexpectedError implements ApplicationError {
    constructor(public innerMessage: string, public stackTrace: string) {
        this.code = 1;
    }
    public code: number;
    public message: string = ErrorMessage.CLIENT_ERROR_MESSAGE;
}

export class ServerError implements ApplicationError {
    constructor(
        public code: number,
        public message: string,
        public errors: any[]
    ) {}
    public stackTrace = '';
    public notify = true;
    public notificationType = 'info';
}
