<div class="main-content">
  <div class="container-fluid">
    <div class="card">
        <div class="card-header card-header-{{getServerStyle()}}"><div class="start">
            <h4 class="card-title">{{serverName}} Raw Feed</h4>
            <p class="card-category">The feed will auto refresh every 15 seconds</p></div>
            <div class="end">
              <button matTooltip="Refresh List" mat-icon-button (click)="refresh()">
                <mat-icon class="icon">refresh</mat-icon>
              </button>
            </div>
        </div>
        <div class="card-body">
            <div id="typography">
                <div class="row">
                    
                  <ul>
                    <li *ngFor="let feed of rawFeed">
                      {{ feed.datetime | date: "h:mm a" }}:
                      {{
                        feed.raw_text.substring(
                          feed.raw_text.indexOf("]") + 1,
                          feed.raw_text.length
                        )
                      }}
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </div>
</div>