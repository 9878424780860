import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-credit",
  templateUrl: "./credit.component.html",
  styleUrls: ["./credit.component.scss"],
})
export class CreditComponent implements OnInit {


  constructor() {}


  ngOnInit(): void {
  }

}
