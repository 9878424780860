import { Component, OnInit, Input } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {

  constructor(public location: Location) {}


  ngOnInit(): void {
  }


  public changeTheme(color){
    localStorage.setItem("theme", color);
    location.reload();
  }


}
