import { Component, Inject, Renderer2 } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public theme: string;

  constructor(
    public dialog: MatDialog,
    public location: Location,
    public router: Router
  ) {}

  ngOnInit(): void {
    var theme = localStorage.getItem("theme");
    if (theme == undefined || theme == "" || theme !== "light") {
      this.theme = "dark";
    }else {
      this.theme = "light";
    }
  }

}
