<div class="main-content col-lg-12 col-md-12 col-sm-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <h4 class="card-title">Site Credits</h4>
            </div>
          </div>
          <div class="card-body">
            <p>
              Hello, this page is here to give a quick shout out to those that make this project possible.<br/>
            </p>
            <h3>Special Note</h3>
            <p>
              To start, although the code and work on this site are the product of the developers (listed below) the site is inspired by the old, original tlpauctions.com which was taken down before the Aradune Server launch in 2020. 
            </p><p> To those that don't know, that site was around for years and offered a free and convenient way for players to scan prices, trends, and view data for EQ servers prior to the bazaar launch. That site was run by someone else. He ended
            up taking the site down prior to the Aradune launch which left a hole in this space for the community. This project, although not related, hopes to continue that legacy of offering free auction data to the community of EQ Tlps. The previous creator is not involved in this project, but the contribution of the idea and concept is still theirs. 
            </p>
            <p>To be clear, this is not the same project as tlpauctions.com from before the Aradune server launch. We are not trying to steal, take credit for, or leech off of their work. The purpose of this site is to provide a similar service since their project was discontinued. </p>
          <br/>
          <h3>The Devs</h3>
          <p>The development work for the site and its features is a shared work between <strong>Naiderye and Abysmaul</strong> on discord. Naiderye handles the website, API, and discord features. Abysmaul handles the parsing of the data and dealing with the EQ log.</p>
          <br/>
          <h3>Mods and Other stuff</h3>
          <p>Thank you to the mods of the discords for helping to monitor the chat, help out users in the discord, and for helping with the discord bot and testing. This would be both <strong>David Mcgoat and Baulkin</strong> on discord.</p>
            <br/>
            <h3>Donors</h3>
            <p>Since this project is completely free and we do not charge for any features or tools, thank you to all the people that donate to help keep the hosting fees paid for along with the accounts that run the logging accounts. </p>
            <br/>
            <h3>The Users</h3>
            <p>Without people using these tools, there would be very little need to use them. So thank you all.</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
