<div class="wrapper {{theme}}">
    <div class="sidebar" data-color="danger" data-background-color="white">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
       <div class="col-lg-12 col-md-12 col-sm-12"> 
           <div class="row">
           <div class="col-lg-6 col-md-12 col-sm-12">
            <app-krono-price serverName="Teek" ></app-krono-price>
           </div>
        </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
    
