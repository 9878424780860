import * as _ from 'lodash';
import {ModelImplementor, ModelPageListImplementor} from '../helpers/implementor.model';
import {PagedList} from '../helpers/paged-list.model';
import {MapperType} from '../helpers/mapper-type';

export interface SalesLogListItem {
  id: number;
  datetime: string;
  transaction_type: boolean;
  auctioneer: string;
  item: string;
  item_id: number;
  plat_price: number;
  krono_price:number;
  raw_guid: string;
}

export class SalesLog extends ModelImplementor
implements SalesLogListItem {
    id: number;
    datetime: string;
    transaction_type: boolean;
    auctioneer: string;
    item: string;
    item_id: number;
    plat_price: number;
    krono_price:number;
    raw_guid: string;

    constructor(options: any) {
      const mapper: MapperType[] = [
        { in: 'id', out: 'id', type: 'any' },
        { in: 'datetime', out: 'datetime', type: 'string' },
        { in: 'transaction_type', out: 'transactionType', type: 'boolean' },
        { in: 'auctioneer', out: 'auctioneer', type: 'any' },
        { in: 'item', out: 'item', type: 'any' },
        { in: 'item_id', out: 'itemId', type: 'number' },
        { in: 'plat_price', out: 'platPrice', type: 'number' },
        { in: 'krono_price', out: 'kronoPrice', type: 'number' },
        { in: 'raw_guid', out: 'rawGuid', type: 'any' }
      ];

      super(options, mapper);
    }
}

export class SalesLogListModel extends ModelPageListImplementor<SalesLogListItem>{
  constructor(options: any) {
    super(options, SalesLog);
  }
}
