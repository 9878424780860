import { Component, OnInit, Input } from "@angular/core";
import { SalesLogProvider } from "../../providers/sales-log-provider.service";

@Component({
  selector: "app-krono-price",
  templateUrl: "./krono-price.component.html",
  styleUrls: ["./krono-price.component.scss"],
})
export class KronoPriceComponent implements OnInit {
  @Input() serverName: string;
  public kronoPrice: number;

  constructor(private salesLogProvider: SalesLogProvider) {}

  getServerStyle(){
    if(this.serverName == "Mischief"){return "danger"}
    if(this.serverName == "Yelinak"){return "success silver"}
    if(this.serverName == "Oakwynd"){return "success gold"}
    else { return "success"};
  }

  ngOnInit(): void {
    this.salesLogProvider
      .getKronoPrice(this.serverName)
      .subscribe((data) => (this.kronoPrice = data));
  }

}
