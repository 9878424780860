<span>
    {{data.text}}
  </span>

<button
  *ngIf="!data.hideCloseBtn"
  class="mat-icon-button cb-icon-button"
  (click)="dismiss()">
X
</button>
