import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-donation",
  templateUrl: "./donation.component.html",
  styleUrls: ["./donation.component.scss"],
})
export class DonationComponent implements OnInit {
  @Input() serverName: string;
  public kronoPrice: number;

  constructor() {}


  ngOnInit(): void {
  }

}
