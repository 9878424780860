import * as _ from 'lodash';
import { MapperType } from './mapper-type';
import { PagedList, PagingInfo } from './paged-list.model';
import { EmptyPagedList } from './paged-list.model';
import {
    convertDateToNonTimezoneString,
    getNonUtcDateFromString
} from '../helpers/date-helpers';


export class ModelImplementor {
    private readonly mapper: MapperType[];

    constructor(options: any, mapper: MapperType[]) {
        this.mapper = mapper || [];
        this.initialize(options);
    }

    protected getValue(src: any, type: string, defaultValue: any) {
        switch (type) {
            case 'any':
                return src;
            case 'string':
                return src && typeof src === 'string'
                    ? src
                    : defaultValue || '';
            case 'number':
                return (src || src === 0) && typeof src === 'number'
                    ? src
                    : defaultValue || 0;
            case 'boolean':
                return typeof src === 'boolean'
                    ? src
                    : src === 'false' || src === 'true'
                    ? src
                    : defaultValue || false;
            case 'date':
                return src && typeof src === 'string'
                    ? getNonUtcDateFromString(src)
                    : defaultValue || null;
            default:
                return '';
        }
    }

    protected initialize(options: any) {
        _.map(this.mapper, item => {
            this[item.in] = this.getValue(
                options[item.out],
                item.type,
                item.default
            );
        });
    }

    protected mapValue(src: any, type: string) {
        switch (type) {
            case 'any':
                return src;
            case 'string':
            case 'number':
            case 'boolean':
                return src !== undefined ? src : undefined;
            case 'date':
                return src ? convertDateToNonTimezoneString(src) : undefined;
            default:
                return undefined;
        }
    }

    public setValues(options) {
        _.map(this.mapper, item => {
            if (options[item.in] !== undefined) {
                this[item.in] = options[item.in];
            }
        });
    }

    public getBaseMappedObject(): any {
        const result = {};
        _.map(this.mapper, item => {
            result[item.out] = this.mapValue(this[item.in], item.type);
        });
        return result;
    }
}

export class ModelPageListImplementor<T> {
    paging_info: PagingInfo;
    has_next: boolean;
    has_previous: boolean;
    total_count: number;
    items: T[];

    constructor(options: any = {}, classCreator: any) {
        const defaultOptions: PagedList<T> = emptyPagedList<T>();
        this.total_count = options.total_count || defaultOptions.total_count;
        this.paging_info = options.paging_info || defaultOptions.paging_info;
        this.has_next = options.has_next || defaultOptions.has_next;
        this.has_previous = options.has_previous || defaultOptions.has_previous;
        this.items = createValuedList<T>(options.items, classCreator);
    }
}

export function emptyPagedList<T>() {
    return <PagedList<T>>(
        _.extend(EmptyPagedList<T>(), { has_next: false, has_previous: false })
    );
}

export function createValuedList<T>(items: any, classCreator): T[] {
    return items && items.length
        ? _.map(items, (item: any) => new classCreator(item))
        : [];
}
