export class ErrorMessage {
    public static SERVER_ERROR = 'Server error. Connection was lost';
    public static CLIENT_ERROR_MESSAGE =
        'An error has occurred. Please use discord here for help: https://discord.gg/YAtbdBn';
}

export class ErrorMessageType {
    public static INFO = 'info';
    public static WARNING = 'warning';
    public static ERROR = 'error';
}
