import { OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PagedList, PageRequestOptions } from "../../helpers/paged-list.model";
import { PageProcessor } from "../../helpers/page-helpers";
import { SelectionChange, SelectionModel } from "@angular/cdk/collections";
import { ToasterService } from "../../providers/toaster.service";

export abstract class PagedTableComponent<ItemType> implements OnDestroy {
  protected subscription: any;
  public actualTableList: ItemType[];
  public pageSize: number;
  public queryString: string;
  public startPage: number;
  public isLoading: boolean;
  public allowMore: boolean;
  public totalItems: number;
  public dataSource = new MatTableDataSource<ItemType>();
  public selection = new SelectionModel<ItemType>(true, [], true);

  protected constructor(protected toasterService: ToasterService) {
    this.setInitialPageConfig();
    this.selection.changed.subscribe((value: SelectionChange<ItemType>) =>
      this.selectionChanged(value, this.selection.selected)
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setInitialPageConfig() {
    this.isLoading = false;
    this.allowMore = false;
    this.totalItems = 0;
    this.queryString = "";
    this.startPage = 1;
    this.pageSize = 15;

    this.actualTableList = [];
  }

  protected errorHandler(error) {
    this.toasterService.showError(error.message);
    this.isLoading = false;
  }

  protected abstract getDataFromSource(queryString: string, source?): void;

  protected selectionChanged(
    selection: SelectionChange<ItemType>,
    selected: ItemType[]
  ): void {}
  protected onAfterUpdate(): void {}

  protected updateTableList(
    data: PagedList<ItemType>,
    mapper?: (value: ItemType, index?: number, array?: ItemType[]) => ItemType,
    filter?
  ) {
    this.allowMore = data.has_next;
    this.totalItems = data.total_count;
    this.startPage = filter ? 1 : this.startPage;
    this.actualTableList = PageProcessor.proceedPageList<ItemType>(
      this.actualTableList,
      data,
      this.getPageOptions(filter),
      mapper
    );
    this.dataSource.data = this.actualTableList;
    this.onAfterUpdate();
    this.isLoading = false;
  }

  protected getPageOptions(queryString: string): PageRequestOptions {
    const options = {
      queryString,
      pageSize: this.pageSize.toString(),
      pagesNumber: this.startPage.toString(),
    };
    return options;
  }

  protected switchToFirstPage(clearFilters?: boolean) {
    this.startPage = 1;
    this.actualTableList = [];
    if (clearFilters) {
      this.queryString = "";
    }
  }

  public showGetMore() {
    if (this.actualTableList && this.actualTableList.length < this.totalItems) {
      return true;
    }
  }

  public getMore(source) {
    this.startPage++;
    const args = source ? [this.queryString, source] : [this.queryString];
    this.getDataFromSource.apply(this, args);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAll() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
}
