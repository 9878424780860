import {Routes} from '@angular/router';
import {AppComponent} from './components/app/app.component';
import { CreditComponent } from './components/credit/credit.component';
import { DonationComponent } from './components/donation/donation.component';
import {RawFeedComponent} from './components/raw-feed/raw-feed.component';
import {SaleLogListComponent} from './components/sale-log-list/sale-log-list.component';
import { SettingsComponent } from './components/settings/settings.component';

export const routes: Routes = [
    { path: '', component: SaleLogListComponent},
    { path: 'search/:server/:item', component:  SaleLogListComponent},
    { path: 'search/:server', component: SaleLogListComponent},
    { path: 'search', component:  SaleLogListComponent},
    { path: 'rawfeed/:server', component: RawFeedComponent},
    { path: 'rawfeed', component: RawFeedComponent},
    { path: 'donate', component: DonationComponent },
    { path: 'credit', component: CreditComponent},
    { path: 'settings', component: SettingsComponent }
    ];
