import { throwError as observableThrowError, Observable } from 'rxjs';
import { Response } from '@angular/http';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerError } from '../models/application-error.model';
import { ErrorMessage, ErrorMessageType } from '../consts/error.consts';

const defaultStatusCode = 1;

export function handleError(err: any): Observable<any> {
    if (err.status !== 404) {
        console.log(err);
    }
    let jsonErr: any = err;

    if (err instanceof HttpErrorResponse) {
        jsonErr = err.error;
    } else if (err instanceof Response) {
        jsonErr = err.json();
    }

    const notify: boolean =
        jsonErr.notify !== undefined ? jsonErr.notify : true;
    const notificationType: string =
        jsonErr.notificationType || ErrorMessageType.ERROR;
    const errors: any[] = jsonErr.errors;

    // try to extract readable message from server
    const errMsg =
        jsonErr.message ||
        jsonErr.error_description ||
        (err.status
            ? `${err.status} - ${err.statusText}`
            : ErrorMessage.SERVER_ERROR);
    const error = new ServerError(
        err.status || defaultStatusCode,
        errMsg,
        errors
    );
    error.notify = notify;
    error.notificationType = notificationType;
    return observableThrowError(error);
}
