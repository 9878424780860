import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SalesLogListModel } from "../models/sales-log-model";
import { handleError } from "../helpers/handle.error";

@Injectable()
export class SalesLogProvider {
  constructor(private $http: HttpClient) {}

  getSalesLog(options): Observable<SalesLogListModel> {
    if (options.exact === undefined) { options.exact = false; }
    const apiUrl =
      "https://api.tlp-auctions.com/saleslog";
    const params = new HttpParams()
      .set("pageNum", options.page_num)
      .set("pageSize", options.page_size)
      .set("searchTerm", options.search_text)
      .set("filter", options.filter)
      .set("exact", options.exact)
      .set("serverName", options.server_name);

    return this.$http.get(apiUrl, { params }).pipe(
      map((data: any) => new SalesLogListModel(data)),
      catchError((err: any) => handleError(err))
    );
  }

  getKronoPrice(server_name): Observable<number> {
    const apiUrl =
      "https://api.tlp-auctions.com/KronoPrice";
    const params = new HttpParams().set("serverName", server_name);

    return this.$http
      .get(apiUrl, { params })
      .pipe(catchError((err: any) => handleError(err)));
  }
}
