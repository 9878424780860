import { Component, OnInit, OnDestroy } from "@angular/core";
import { SalesLogProvider } from "../../providers/sales-log-provider.service";
import { PagedTableComponent } from "../paged-table/paged-table.component";
import { SalesLogListItem } from "../../models/sales-log-model";
import { ToasterService } from "../../providers/toaster.service";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { PagedList } from "../../helpers/paged-list.model";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ChatLogDialogComponent } from "../chat-log-dialog/chat-log-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-searchable-list",
  templateUrl: "./sale-log-list.component.html",
  styleUrls: ["./sale-log-list.component.scss"],
})
export class SaleLogListComponent extends PagedTableComponent<SalesLogListItem>
  implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "sale_time",
    "auctioneer",
    "transaction_type",
    "item",
    "price",
    "expand",
  ];
  searchQuery: string;
  searchStream = new Subject<string>();
  wtb: boolean;
  wts: boolean;
  exact: boolean;
  serverName: string;

  constructor(
    private salesLogProvider: SalesLogProvider,
    protected toasterService: ToasterService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location
  ) {
    super(toasterService);
    this.pageSize = 25;
  }

  ngOnInit(): void {
    this.getQueryStrings();
   // this.initData();
    this.startSearchInputSubscription();
  }

  getQueryStrings() {
    this.route.params.subscribe((params) => {
      if(params["server"] != undefined){
        this.serverName = params["server"];
        if(this.serverName != "Teek" && this.serverName != "Yelinak" && this.serverName != "Thornblade" && this.serverName != "Mischief" &&
            this.serverName != "Oakwynd"){
          this.serverName = "Teek"
        }
      }
      this.searchQuery = params["item"];
      this.switchToFirstPage();
      this.getDataFromSource();
    });
  }

  initData() {
    this.isLoading = true;
    this.getDataFromSource();
  }

  getServerStyle(){
    if(this.serverName == "Mischief"){return "danger"}
    if(this.serverName == "Yelinak"){return "success silver"}
    if(this.serverName == "Oakwynd"){return "success gold"}
    else { return "success"};
  }
  
  // tslint:disable-next-line:variable-name
  public formatPrice(krono_price, plat_price) {
    let price = "";

    if (krono_price !== 0) {
      price = krono_price + "kr ";
    }
    if (plat_price !== 0) {
      price += plat_price + "pp";
    }
    return price;
  }

  public formatType(type) {
    if (type) {
      return "Buying";
    } else {
      return "Selling";
    }
  }

  searchItem(item) {
    this.searchQuery = item;
    this.switchToFirstPage();
    this.getDataFromSource();
  }

  public refreshList() {
    this.switchToFirstPage();
    this.getDataFromSource();
  }

  protected getDataFromSource(): void {
    this.fetchLogs()
      .pipe(first())
      .subscribe((logs) => {
        this.updateTableList(logs);
        this.isLoading = false;
      });
  }

  fetchLogs() {
    const pageOptions = this.getPageOptions();
    if (this.searchQuery != null)
      this.location.replaceState("/search/" + this.serverName + "/" + this.searchQuery);
    return this.salesLogProvider.getSalesLog(pageOptions);
  }

  public cancelSearch() {
    this.searchQuery = "";
    this.switchToFirstPage();
    this.getDataFromSource();
  }

  protected updateTableList(data: PagedList<SalesLogListItem>) {
    this.allowMore = data.has_next;
    this.totalItems = data.total_count;
    this.actualTableList = _.concat(this.actualTableList, data.items);
    this.dataSource.data = this.actualTableList;
    this.isLoading = false;
  }

  protected getPageOptions(): any {
    const pageSize = this.pageSize.toString();
    const pagesNumber = this.startPage.toString();
    const options = {
      page_size: pageSize,
      pageSize,
      page_num: pagesNumber,
      pagesNumber,
    };
    if (this.searchQuery) {
      options["search_text"] = this.searchQuery;
    } else {
      options["search_text"] = "";
    }
    options["server_name"] = this.serverName;
    options["filter"] = this.getFilter();
    options["exact"] = this.exact;
    return options;
  }

  getFilter() {
    if (this.wtb && this.wts) {
      return "";
    }
    if (this.wtb && !this.wts) {
      return "buy";
    }
    if (!this.wtb && this.wts) {
      return "sell";
    }
    if (!this.wtb && !this.wtb) {
      return "";
    }
  }

  public changeExact() {
    this.switchToFirstPage();
    this.getDataFromSource();
  }

  startSearchInputSubscription() {
    this.subscription = this.searchStream
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((query) => {
        this.searchQuery = query;
        this.switchToFirstPage();
        this.getDataFromSource();
      });
  }

  public showChatLog(rawGuid): void {
    const dialogRef = this.dialog.open(ChatLogDialogComponent, {
      width: "400px",
      data: { guid: rawGuid, serverName: this.serverName },
    });
  }

  public getIcon(transaction_type){
    if(transaction_type) return "shopping_cart" 
    else return "sell";
  }
}
