import { PagedList, PageRequestOptions } from './paged-list.model';
import * as _ from 'lodash';

export class PageProcessor {
  static proceedPageList<SubjListItem>(
    currentList: SubjListItem[],
    newBlock: PagedList<SubjListItem>,
    pageOptions: PageRequestOptions,
    newBlockMapper?: (
      value: SubjListItem,
      index?: number,
      array?: SubjListItem[]
    ) => SubjListItem
  ): SubjListItem[] {
    const pageSize = Number(pageOptions.pageSize) || 0;
    const pageNumber = Number(pageOptions.pagesNumber) || 0;
    let newList = currentList;
    const defaultBlockMapper = i => i;
    const blockMapper = newBlockMapper || defaultBlockMapper;

    if (newBlock) {
      console.log(newBlock);
      const sizeOfContact = currentList ? currentList.length || 0 : 0;
      const expectedSize = pageSize * pageNumber;
      const sizeOfPage = newBlock.items.length;
      const isExpectedSizeValid =
        sizeOfContact < expectedSize &&
        sizeOfContact >= expectedSize - pageSize;
      const mappedPage: SubjListItem[] = newBlock.items.map(blockMapper);
      if (!sizeOfContact || pageOptions.queryString || pageNumber === 1) {
        newList = newBlock.items;
      } else if (isExpectedSizeValid) {
        newList = _.concat(currentList, mappedPage);
      } else {
        newList = _.concat(
          _.take(currentList, sizeOfContact - sizeOfPage),
          mappedPage
        );
      }
      return newList;
    }
  }
}

