<div class="main-content col-lg-12 col-md-12 col-sm-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-{{ getServerStyle() }} row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <h4 class="card-title">{{ serverName }} Sale Log</h4>
              <p class="card-category">The feed does not auto refresh</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-md-9 col-sm-7 col-xs-7 col-7">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      [(ngModel)]="searchQuery"
                      (ngModelChange)="searchStream.next($event)"
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-4 col-4">
                  <button
                    matTooltip="Cancel Search"
                    mat-icon-button
                    (click)="cancelSearch()"
                  >
                    <mat-icon class="icon">cancel</mat-icon>
                  </button>
                  <button
                    matTooltip="Refresh List"
                    mat-icon-button
                    (click)="refreshList()"
                  >
                    <mat-icon class="icon">refresh</mat-icon>
                  </button>
                </div>
              </div>
              <div
                style="padding-left: 0px; margin-left:-15px;"
                class="col-lg-12 col-md-12 col-sm-12"
                
              >
                <section>
                  <mat-checkbox
                    class="col-3"
                    [(ngModel)]="wtb"
                    (change)="refreshList()"
                    >Buying</mat-checkbox
                  >
                  <mat-checkbox
                    class="col-3"
                    [(ngModel)]="wts"
                    (change)="refreshList()"
                    >Selling</mat-checkbox
                  >
                  <mat-checkbox
                    class="col-3"
                    [(ngModel)]="exact"
                    (change)="changeExact()"
                    >Exact Match</mat-checkbox
                  >
                </section>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSource"
                matSort
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="sale_time">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Time
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.datetime + '-0400' | date: "M/d, h:mm a" }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="auctioneer">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Auctioneer</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row">
                    <a (click)="searchItem(row.auctioneer)">
                      {{ row.auctioneer }}
                    </a></mat-cell
                  >
                </ng-container>
                  <ng-container matColumnDef="transaction_type">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Type</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" >
                    <span class="type_text"><strong>
                      {{ formatType(row.transaction_type) }}</strong>
                    </span>
                          <span class="type_icon">

                      <i class="material-icons">{{getIcon(row.transaction_type)}}</i>
                    </span>
                      </mat-cell>
                  </ng-container>
                <ng-container matColumnDef="item">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Item</mat-header-cell
                  >
                  <mat-cell md-truncate *matCellDef="let row">
                    <a
                      (click)="searchItem(row.item)"
                      rel="eq:item:{{ row.item_id }}"
                    >
                      {{ row.item }}
                    </a></mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="price">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Price</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row">
                    {{ formatPrice(row.krono_price, row.plat_price) }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expand">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Log</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row">
                    <button mat-button (click)="showChatLog(row.raw_guid)">
                      +
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="{'buy': row.transaction_type, 'sell': !row.transaction_type}"
                ></mat-row>
              </mat-table>
            </div>
            <div *ngIf="showGetMore()" class="get-more">
              <button
                mat-raised-button
                [disabled]="!allowMore"
                (click)="getMore('')"
              >
                Get More
                {{ actualTableList.length }}/{{ totalItems }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
