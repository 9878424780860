import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routes } from './routes.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SaleLogListComponent } from './components/sale-log-list/sale-log-list.component';
import { SalesLogProvider } from './providers/sales-log-provider.service';
import { HttpClient, HttpHandler, HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule } from '@angular/material/paginator';
import { CustomMatSnackBarComponent } from './components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import {ToasterService} from './providers/toaster.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import { ChatLogDialogComponent } from './components/chat-log-dialog/chat-log-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {RawLogProvider} from './providers/raw-log-provider.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {StorageServiceModule} from 'ngx-webstorage-service';
import { KronoPriceComponent } from './components/krono-price/krono-price.component';
import { RawFeedComponent } from './components/raw-feed/raw-feed.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ServerSelectDialog } from './components/server-select-dialog/server-select-dialog.component';
import { DonationComponent } from './components/donation/donation.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CreditComponent } from './components/credit/credit.component';
@NgModule({
  declarations: [
    AppComponent,
    SaleLogListComponent,
    CustomMatSnackBarComponent,
    ChatLogDialogComponent,
    ServerSelectDialog,
    KronoPriceComponent,
    RawFeedComponent,
    SidebarComponent,
    DonationComponent,
    SettingsComponent,
    CreditComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatCheckboxModule,
    StorageServiceModule,
      RouterModule.forRoot(routes)
  ],
  providers: [SalesLogProvider, RawLogProvider, HttpClient, ToasterService],
  bootstrap: [AppComponent]
})
export class AppModule { }
