<div class="main-content col-lg-12 col-md-12 col-sm-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <h4 class="card-title">Want to Help Support the Site?</h4>
              <p class="card-category">Thank you!</p>
            </div>
          </div>
          <div class="card-body">
            <p>
              Hello, <br/>Thank you for considering donating to the operation and
              development of this website.
            </p>
            <p>
              First, I would like to note that you are under NO OBLIGATION to
              donate. The site, discord, and API are all free. If anyone tells
              you that you are required to pay then please reach out to me on
              discord at Dumion#9644. I started this project as a way to offer
              these services for free to the community of a game that I love
              playing. It evolved into a much bigger project with a wide
              community of its own.
            </p>
            <p>Any and all donations are appreciated and nonrefundable. I am currently accepting a few different forms of donation to help support the site and its development. You can view them below and chose the option that best suits you!</p>
            <p>
              You can donate via PayPal by clicking this <br/><a href="https://paypal.me/tlpauction" target="_blank"><button mat-raised-button >PayPal</button></a>
            </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
