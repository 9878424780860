import { Component, OnInit } from "@angular/core";
import { RawLogProvider } from "../../providers/raw-log-provider.service";
import { first } from "rxjs/operators";
import { interval } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-raw-feed",
  templateUrl: "./raw-feed.component.html",
  styleUrls: ["./raw-feed.component.scss"],
})
export class RawFeedComponent implements OnInit {
  public rawFeed;
  public ONE_HOUR = 60 * 60 * 1000;
  public dateTime = new Date();
  public serverName = "Teek";

  constructor(private rawLogProviderService: RawLogProvider,
    private route: ActivatedRoute,) {
    this.dateTime = new Date(this.dateTime.getTime() - this.ONE_HOUR);
  }

  ngOnInit(): void {
    this.getQueryStringParams();
    this.fetchLogs();
    interval(15000).subscribe(() => {
      this.fetchLogs();
    });
  }

  getQueryStringParams(){
    this.route.params.subscribe((params) => {
      if(params["server"] != undefined){
        this.serverName = params["server"];
        if(this.serverName != "Teek" && this.serverName != "Thornblade" && this.serverName != "Mischief" && this.serverName != "Oakwynd"){
          this.serverName = "Mischief";
        }
        this.fetchLogs();
        console.log(this.serverName);
      }
    });
  }

  getServerStyle(){
    if(this.serverName == "Mischief"){return "danger"}
    else { return "success"};
  }

  fetchLogs() {
    this.rawLogProviderService
      .getRawFeed(this.dateTime, this.serverName)
      .pipe(first())
      .subscribe((logs) => {
        if (this.rawFeed === undefined) {
          this.rawFeed = logs;
        } else {
          logs
            .slice()
            .reverse()
            .forEach((x) => this.rawFeed.unshift(x));
        }
        if(logs.length > 100 || this.rawFeed.length > 100){
          this.rawFeed.length = 100;
        }
        this.dateTime = this.rawFeed[0].datetime;
      });
  }

  refresh() {
    this.fetchLogs();
  }
}
