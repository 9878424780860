<div class="main-content col-lg-12 col-md-12 col-sm-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <h4 class="card-title">Change Settings</h4>
              <p class="card-category"></p>
            </div>
          </div>
          <div class="card-body">
            <h3>Color Theme</h3>
            <button mat-raised-button (click)="changeTheme('light')">Light</button>
            <button mat-raised-button (click)="changeTheme('dark')">Dark</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
