<div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card card-stats">
        <div class="card-header card-header-{{getServerStyle()}} card-header-icon">
            <div class="card-icon">
                <i class="material-icons">attach_money</i>
            </div>
            <p class="card-category">{{serverName}} Krono Price</p>
            <h3 class="card-title">{{kronoPrice | number: '1.0-0' }}pp</h3>
        </div>
        <div class="card-footer">
            <div class="stats">
                <i class="material-icons">information</i> Average over 3 days
            </div>
        </div>
    </div>
</div>