<div class="logo">TLP Auctions</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/search/Teek">
        <i class="material-icons">dashboard</i>
        <p>Teek Sale Log</p>
      </a>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        target="_blank"
        href="https://api.tlp-auctions.com/swagger/index.html"
      >
        <i class="material-icons">code</i>
        <p>API Documentation</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://discord.gg/YAtbdBn">
        <i class="material-icons">chat</i>
        <p>Discord</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/settings">
        <i class="material-icons">settings</i>
        <p>Settings</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link old-server-1" routerLink="/search/Oakwynd">
        <i class="material-icons">dashboard</i>
        <p>Oakwynd Sale Log</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link old-server-2" routerLink="/search/Mischief">
        <i class="material-icons">dashboard</i>
        <p>Mischief Sale Log</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link old-server-3" routerLink="/search/Thornblade">
        <i class="material-icons">dashboard</i>
        <p>Thornblade Sale Log</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link donate-menu" routerLink="/donate">
        <i class="material-icons">attach_money</i>
        <p>Support the site?</p>
      </a>
    </li>
  </ul>
</div>
