import { Component, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-mat-snack-bar',
  templateUrl: 'custom-mat-snack-bar.component.html',
  styleUrls: ['custom-mat-snack-bar.component.scss']
})

export class CustomMatSnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>,
    private readonly zone: NgZone,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  dismiss() {
    this.zone.run(() => {
        this.snackBarRef.dismiss();
      }
    );
  }
}
