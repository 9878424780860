import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomMatSnackBarComponent } from '../components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import * as _ from 'lodash';

@Injectable()
export class ToasterService {
  private readonly errorConfig: MatSnackBarConfig;
  private readonly infoConfig: MatSnackBarConfig;
  private readonly warningConfig: MatSnackBarConfig;
  private snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>;
  private isInstanceVisible: boolean;
  private messageQueue: Array<any> = Array<any>();

  constructor(
    private snackBar: MatSnackBar
  ) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = 0;
    this.errorConfig = Object.assign({}, config, {
      panelClass: ['error']
    });
    this.warningConfig = Object.assign({}, config, {
      panelClass: ['warning']
    });
    this.infoConfig = Object.assign({}, config, {
      panelClass: ['info'],
      duration: 3000
    });
  }

  showError(msg: string, localizedString = false) {
      this.show(msg, null, this.errorConfig);
  }

  showWarning(msg: string, localizedString = false) {
      this.show(msg, null, this.warningConfig);
  }

  showInfo(msg: string, localizedString = false) {
      this.show(msg, null, this.infoConfig, true);
  }
  show(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    hideCloseBtn?: boolean
  ): void {
    if (!config) {
      config = new MatSnackBarConfig();
      config.duration = 0;
      config.verticalPosition = 'bottom';
      config.horizontalPosition = 'end';
    }

    const sbMessage = {
      message,
      action,
      config,
      hideCloseBtn
    };
    if (this.messageQueue.length) {
      this.messageQueue.forEach(
        item => {
          if (!_.isEqual(item, sbMessage)) {
            this.messageQueue.push(sbMessage);
          }
        }
      );
    } else {
      this.messageQueue.push(sbMessage);
    }

    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  private showNext() {
    if (this.messageQueue.length === 0) {
      return;
    }

    const message = this.messageQueue.shift();
    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.openFromComponent(CustomMatSnackBarComponent, {
      ... message.config,
      ... message.action,
      data:  {
        text: message.message,
        hideCloseBtn: message.hideCloseBtn
      }
    });
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isInstanceVisible = false;
      this.showNext();
    });
  }

}
