import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SalesLogListModel } from "../models/sales-log-model";
import { handleError } from "../helpers/handle.error";
import { RawLogModel } from "../models/raw-log-model";

@Injectable()
export class RawLogProvider {
  constructor(private $http: HttpClient) {}

  getRawLog(rawGuid, serverName): Observable<RawLogModel> {
    const apiUrl =
      "https://api.tlp-auctions.com/RawLog";
    const params = new HttpParams()
      .set("rawGuid", rawGuid)
      .set("serverName", serverName);

    return this.$http.get(apiUrl, { params }).pipe(
      map((data: any) => data),
      catchError((err: any) => handleError(err))
    );
  }

  getRawFeed(dateTime, serverName) {
    const apiUrl =
      "https://1nnqpdn622.execute-api.us-east-1.amazonaws.com/default/GetRawFeed";
    const params = new HttpParams()
      .set("dateTime", dateTime)
      .set("serverName", serverName);

    return this.$http.get(apiUrl, { params }).pipe(
      map((data: any) => data),
      catchError((err: any) => handleError(err))
    );
  }
}
